import { DocumentSigningStatus } from "@/constants/SignatureConstants";
import {AccountType} from "@/constants/AccountTypes";

export default class SignableDocumentsFilter {

    pageNumber = 1;
    pageSize = 20;
    signingStatus: DocumentSigningStatus | null = null;
    signerId: number | null = null;
    signerType: AccountType | null = null;
    assigneeId: number | null = null;
    createDateFrom: Date | null = null;
    createDateTo: Date | null = null;
}
