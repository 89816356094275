






























import {Vue, Component, Prop} from 'vue-property-decorator';
import SimpleCompanySearch from "@/components/common/SimpleCompanySearch.vue";
import SingleFileHolder from "@/components/util/SingleFileHolder.vue";
import PortalSelect from "@/components/common/PortalSelect.vue";
import SelectOption, {OptionsBuilder} from "@/components/common/SelectOption";
import {SignableDocumentType, SpouseSigningMode} from "@/constants/SignatureConstants";
import SignatureService from "@/services/SignatureService";
import {namespace} from "vuex-class";
import {processError} from "@/utils/ComponentUtils";
import CreatePrefilledPersonDocumentPayload from "@/dto/signature/CreatePrefilledPersonDocumentPayload";
import PersonDTO from "@/dto/person/PersonDTO";
import PersonSearch from "@/components/common/PersonSearch.vue";

const AppModule = namespace("App");

@Component({
  computed: {
    SpouseSigningMode() {
      return SpouseSigningMode
    }
  },
  components: {PersonSearch, PortalSelect, SingleFileHolder}
})
export default class RequestPersonSignatureForFilledDocumentModal extends Vue {

  @AppModule.Action private startLoading!: () => void
  @AppModule.Action private stopLoading!: () => void

  @Prop()
  onCreated!: () => void

  payload = new CreatePrefilledPersonDocumentPayload();

  personSelected(p: PersonDTO): void {
    this.payload.personId = p.id
  }

  getSpouseSigningModes(): Array<SelectOption>{
    return OptionsBuilder
        .of("Spouse signature not required", SpouseSigningMode.IGNORED)
        .of("Request spouse signature if spouse is present", SpouseSigningMode.OPTIONAL)
        .of("Spouse signature is mandatory", SpouseSigningMode.REQUIRED)
        .get()
  }

  getDocumentTypes(): Array<SelectOption> {
    return OptionsBuilder
        //Tax return 2024
        .of("Form TR-2000", SignableDocumentType.FORM_TR_2000_2021)
        .of("Form 8879, tax return 2024 (rev. 2021)", SignableDocumentType.FORM_8879_2024_R2021)
        .of("Form 4506-T, tax return 2024 (rev. 2024)", SignableDocumentType.FORM_4506_T_R2024)
        .of("Form TR-579-IT, tax return 2024 (rev. 2024)", SignableDocumentType.FORM_TR_579_IT_2024_R2024)
        .of("Form 8453 Georgia, tax return 2024 (rev. 2024)", SignableDocumentType.FORM_8453_GEORGIA_R2024)
        .of("Form DR-8454 Colorado, tax return 2024 (rev. 2024)", SignableDocumentType.FORM_DR_8454_COLORADO_2024_R2024)
        .of("Form PA-8453, tax return 2024 (rev. 2024)", SignableDocumentType.FORM_PA_8453_2024_R2024)
        .of("Form IL-8453, tax return 2024 (rev. 2024)", SignableDocumentType.FORM_IL_8453_2024_R2024)
        //Revision 2022
        .of("Form M-8453 rev. 2022", SignableDocumentType.FORM_M_8453_MASSACHUSETTS_R2022)
        .of("Form VA-8879, rev. 2022", SignableDocumentType.FORM_VA_8879_VIRGINIA_R2022)
        .of("Form EL-101, rev. 2022", SignableDocumentType.FORM_EL101_MARYLAND_R2022)
        .of("Form TR-579-IT, rev. 2022", SignableDocumentType.FORM_TR_579_IT_R2022)
        .of("Form CA-8453, rev. 2022", SignableDocumentType.FORM_CA_8453_R2022)
        .of("Form 8453 Georgia, rev. 2022", SignableDocumentType.FORM_8453_GEORGIA_R2022)
        .of("Form DR-8454 Colorado, rev. 2022", SignableDocumentType.FORM_DR_8454_COLORADO_R2022)
        .of("Form SC-8453, rev. 2022", SignableDocumentType.FORM_SC_8453_R2022)
        .of("Form AZ-8879, rev. 2022", SignableDocumentType.FORM_AZ_8879_R2022)
        //Tax return 2022
        .of("Form 8879, tax return 2022 (rev. 2021)", SignableDocumentType.FORM_8879_2022_R2021)
        // .of("Form IL-8453", SignableDocumentType.FORM_IL_8453_ILLINOIS_2022)
        //Tax return 2021
        .of("Form 8879, tax return 2021 (rev. 2021)", SignableDocumentType.FORM_8879_2021_R2021)
        .of("Form PA-8453, tax return 2021 (rev. 2021)", SignableDocumentType.FORM_PA_8453_2021_R2021)
        .of("Form 2848, rev. 2021", SignableDocumentType.FORM_2848_R2021)
        .of("Form 8879, tax return 2020 (rev. 2021)", SignableDocumentType.FORM_8879_2020_R2021)
        .of("Form PA-8453, tax return 2020 (rev. 2020)", SignableDocumentType.FORM_PA_8453_2020_R2020)
        .of("Form PA-8453, tax return 2019 (rev. 2019)", SignableDocumentType.FORM_PA_8453_2019_R2019)
        .of("Form 8879, tax return 2019 (rev. 2019)", SignableDocumentType.FORM_8879_2019_R2019)
        .of("Form POA-1, rev. 2017", SignableDocumentType.FORM_POA_1_R2017)
        // .of("Form 2553", SignableDocumentType.FORM_2553)




  .get()
  }

  save(): void {
    this.startLoading();
    SignatureService.createPrefilledPersonDocument(this.payload).then(
        ok => {
          this.$modal.hideAll();
          this.stopLoading();
          this.onCreated();
        },
        err => {
          this.stopLoading();
          processError(err, this);
        }
    )
  }

}
